/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css */
.root {
  height: 100vh;
}

/* Base button styling */
.invisible-button {
  position: relative;
  overflow: hidden; /* Ensures the ripple is contained within the button */
  background-color: transparent;
  color: white;
  border: none;
  padding: 0px 0px;
  margin: 0px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  height: 100%;
  width: 100%;
}

/* Ripple effect */
.ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5); /* Ripple color */
  transform: scale(0);
  animation: ripple-animation 600ms linear;
  pointer-events: none;
}

/* Ripple animation keyframes */
@keyframes ripple-animation {
  to {
    transform: scale(4); /* Make the ripple expand outward */
    opacity: 0; /* Fade out the ripple */
  }
}

/* Optional: You can adjust the ripple-container class to suit your design needs */
.ripple-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
